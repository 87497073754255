svg.nav-icon {
  margin-left: 0.5rem;
  color: rgb(65, 118, 118);
}

.small-menu-icon {
  color: white;
}

.green-icon {
  color: rgb(65, 118, 118);
}

.orange-icon {
  color: rgb(118, 65, 65);
}
